var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResizeHandler,
          expression: "onResizeHandler",
        },
      ],
      staticClass: "pa-1 product-view",
      style: "height: " + _vm.windowSize.y + "px; position: 'relative'",
      attrs: { flat: "" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "primary",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            {
              ref: "receiveForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0 font-weight-bold text-uppercase",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.orderModel.number) + " "),
                      _vm.modeOfView === _vm.CONTSTANTS.RECEIVE
                        ? [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.$t("message.order.receiving")) +
                                ")"
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pr-1 pb-0 mt-1",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("barcode-scanner", {
                        attrs: {
                          search: _vm.scanProduct,
                          scannerPayload: _vm.scannerPayload,
                          loading: _vm.productLoading,
                        },
                        model: {
                          value: _vm.scanProduct,
                          callback: function ($$v) {
                            _vm.scanProduct = $$v
                          },
                          expression: "scanProduct",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.productFound
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-1 pb-1 font-weight-bold",
                          attrs: { cols: "12" },
                        },
                        [
                          !_vm.isNewProduct
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.productInfo.productname || "")
                                ),
                              ])
                            : _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.productInfo.name || "")),
                              ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.productFound
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-1 px-1 pb-0", attrs: { cols: "6" } },
                        [
                          !_vm.locationSelectedAlready
                            ? _c("v-autocomplete", {
                                ref: "scanLocation",
                                attrs: {
                                  items: _vm.listOfLocations,
                                  "item-text": "name",
                                  "item-value": "name",
                                  "hide-details": "",
                                  outlined: "",
                                  loading: _vm.loadingLocation,
                                  label: _vm.$t("message.product.scanLoc"),
                                  flat: "",
                                  autocomplete: "off",
                                  dense: "",
                                  rules: _vm.$_requiredValidation,
                                },
                                on: { change: _vm.checkIfLocationValid },
                                model: {
                                  value: _vm.selectedLocation,
                                  callback: function ($$v) {
                                    _vm.selectedLocation = $$v
                                  },
                                  expression: "selectedLocation",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.productFound &&
                      _vm.productInfo.isbatch &&
                      _vm.modeOfView === _vm.CONTSTANTS.COLLECT
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pt-1 px-1 pb-0",
                              attrs: { cols: "6" },
                            },
                            [
                              [
                                _c("v-autocomplete", {
                                  ref: "batchRef",
                                  attrs: {
                                    items: _vm.listOfBatchNumbers,
                                    "item-text": "batchnumberpwa",
                                    "item-value": "batchnumberpwa",
                                    "hide-details": "",
                                    outlined: "",
                                    loading: _vm.loadingBatch,
                                    label: _vm.$t("message.product.batch"),
                                    flat: "",
                                    autocomplete: "off",
                                    dense: "",
                                    rules: _vm.$_requiredValidation,
                                    placeholder: _vm.$t(
                                      "message.product.batch"
                                    ),
                                  },
                                  on: { change: _vm.checkIfBatchContainsQty },
                                  model: {
                                    value: _vm.selectBatch,
                                    callback: function ($$v) {
                                      _vm.selectBatch = $$v
                                    },
                                    expression: "selectBatch",
                                  },
                                }),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productFound
                ? _c(
                    "v-row",
                    [
                      _vm.modeOfView === _vm.CONTSTANTS.RECEIVE
                        ? [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1 pl-2",
                                attrs: {
                                  cols:
                                    _vm.modeOfView === _vm.CONTSTANTS.RECEIVE
                                      ? "8"
                                      : "10",
                                },
                              },
                              [
                                _c("span", { staticClass: "subheading" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("message.order.availableToPick")
                                    )
                                  ),
                                ]),
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: {
                                      "active-class": "primary text--accent-5",
                                      mandatory: "",
                                    },
                                    model: {
                                      value: _vm.receivedObjIndex,
                                      callback: function ($$v) {
                                        _vm.receivedObjIndex = $$v
                                      },
                                      expression: "receivedObjIndex",
                                    },
                                  },
                                  _vm._l(
                                    _vm.availableItems,
                                    function (picked, index) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: index,
                                          attrs: {
                                            disabled: picked.disabled,
                                            value: picked.id,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(picked.picked_quantity)
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "v-col",
                              { staticClass: "pa-1", attrs: { cols: "9" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center py-0 mt-1",
                                        attrs: { cols: "3" },
                                        on: { click: _vm.decreaseQty },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "info",
                                              fab: "",
                                              "x-small": "",
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("mdi-minus")])],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("v-text-field", {
                                          ref: "qtyRef",
                                          staticClass: "text-right",
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            type: "number",
                                            autocomplete: "off",
                                            rules: _vm.$_qtyValidation,
                                            placeholder: _vm.$t(
                                              "message.product.qty"
                                            ),
                                          },
                                          model: {
                                            value: _vm.pickQty,
                                            callback: function ($$v) {
                                              _vm.pickQty = $$v
                                            },
                                            expression: "pickQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center py-0 mt-1",
                                        attrs: { cols: "3" },
                                        on: { click: _vm.increaseQty },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "info",
                                              fab: "",
                                              "x-small": "",
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("mdi-plus")])],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-1 mt-2",
                          attrs: {
                            cols:
                              _vm.modeOfView === _vm.CONTSTANTS.RECEIVE
                                ? "3"
                                : "2",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                small: "",
                                loading: _vm.saveLoading,
                                disabled:
                                  !(
                                    _vm.availableItems &&
                                    _vm.availableItems.length > 0
                                  ) &&
                                  _vm.modeOfView === _vm.CONTSTANTS.RECEIVE,
                              },
                              on: { click: _vm.receiveQtyOnOrder },
                            },
                            [
                              _vm.modeOfView === _vm.CONTSTANTS.RECEIVE
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.order.receive"))
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.order.pick"))
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "highlight pt-0 pb-0 pl-1 mt-2" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 font-weight-bold",
                  attrs: { cols: "4" },
                },
                [_vm._v(_vm._s(_vm.$t("message.whsDashboard.product")) + ":")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pr-2 text-center font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.unit")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.ordered")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.pickedQty")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.received")))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "pa-0" })],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.listOfProducts, function (product, index) {
            return _c(
              "v-row",
              {
                key: index,
                staticClass: "pb-3",
                on: {
                  click: function ($event) {
                    return _vm.scanProductByClick(product.productnumber)
                  },
                },
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3 text-truncate",
                    class: { "pb-1": index === _vm.listOfProducts.length - 1 },
                    attrs: { cols: "4", title: product.productnumber },
                  },
                  [
                    _vm._v(" " + _vm._s(product.productnumber) + " "),
                    _c("br"),
                    _c("span", [_vm._v(_vm._s(product.productname))]),
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 text-center text-truncate",
                    attrs: { cols: "2", title: product.unitname },
                  },
                  [_vm._v(_vm._s(product.unitname))]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 text-right text-truncate",
                    attrs: { cols: "2" },
                  },
                  [_vm._v(_vm._s(_vm._f("absoluteNumber")(product.qty)))]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3 text-right text-truncate",
                    attrs: { cols: "2" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("absoluteNumber")(product.pickedqty_ref))
                    ),
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3 text-right text-truncate",
                    attrs: { cols: "2" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("absoluteNumber")(product.receivedqty_ref)
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "v-btn",
        {
          attrs: {
            small: "",
            color: "primary",
            dark: "",
            absolute: "",
            bottom: "",
            left: "",
            tabindex: "-1",
          },
          on: {
            click: function ($event) {
              return _vm.$router.push("/transfer_orders/" + _vm.orderId)
            },
          },
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("mdi-arrow-left-bold"),
          ]),
          _vm._v(_vm._s(_vm.$t("message.login.back")) + " "),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            small: "",
            color: "primary",
            dark: "",
            absolute: "",
            bottom: "",
            right: "",
            tabindex: "-1",
          },
          on: { click: _vm.navigateToView },
        },
        [
          _vm.modeOfView === _vm.CONTSTANTS.RECEIVE
            ? [_vm._v(" " + _vm._s(_vm.$t("message.order.collect")) + " ")]
            : [_vm._v(" " + _vm._s(_vm.$t("message.order.receive")) + " ")],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }